import "../styles/index.css";
import "../styles/global.scss";
import { Provider } from "next-auth/client";
import { init } from "../src/api/sentry";

import Script from "next/script";

const VALUE_ENV = process.env.VALUE_ENV;

init();

function MyApp({ Component, pageProps, err }) {
  return (
    <Provider session={pageProps.session}>
      <Component {...pageProps} err={err} />
      <Script src={`${process.env.NEXTAUTH_URL}/js/atc.min.js`} strategy="beforeInteractive" />
      {/* <Script src="https://embed.typeform.com/embed.js" strategy="beforeInteractive" /> */}
      {VALUE_ENV === "production" && <Script async data-api="/_hive" src="/bee.js" />}
    </Provider>
  );
}

export default MyApp;
